import { CAlert } from '@coreui/react';
import AlertContext, { AlertStatus } from '@providers/AlertProvider';
import React, { useContext } from 'react';

export const Alert = () => {
  const alert = useContext(AlertContext);

  if (alert.alert === AlertStatus.none) {
    return null;
  }

  return (
    <CAlert
      dismissible
      color={alert.alert as string}
      variant="flex"
      style={{
        position: 'fixed',
        bottom: '80px',
        right: '30px',
        transition: 'all.3s ease',
        zIndex: '100000',
      }}
      onClose={alert.clear}
    >
      {alert.alertText}
    </CAlert>
  );
};
