import React, { Suspense, useContext, useEffect } from 'react';
import { CSpinner } from '@coreui/react';

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { AuthContext, UserInitialValue } from './providers';
import './scss/style.scss';

type ChildrenProps = {
  children: JSX.Element;
};

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./pages/Login'));

const RequireAuth: React.FC<ChildrenProps> = ({ children }) => {
  const { userData, setUserData } = useContext(AuthContext) as UserInitialValue;
  const location = useLocation();

  useEffect(() => {
    if (userData) {
      if (userData.accessTokenExpiresAt < Date.now()) {
        setUserData(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (!userData) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="*"
            element={(
              <RequireAuth>
                <DefaultLayout />
              </RequireAuth>
            )}
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
