import { UserFromApi } from '@types';
import React, { createContext, useEffect, useState } from 'react';
import { ChildrenProps } from './types';

export type UserInitialValue = {
  userData: UserFromApi | undefined | null;
  setUserData: (user: UserFromApi | null) => void;
};

export const USER_LOCAL_STORAGE_KEY = 'token';

export const AuthContext = createContext<UserInitialValue | null>(null);

export const AuthProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserFromApi | null>();

  useEffect(() => {
    const updateUserData = async () => {
      if (userData === null) {
        window.localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
      } else if (userData !== undefined) {
        const localData = JSON.parse(JSON.stringify(userData));
        const jsonVal = JSON.stringify(localData);
        window.localStorage.setItem(USER_LOCAL_STORAGE_KEY, jsonVal);
      }
    };
    updateUserData();
  }, [userData]);

  useEffect(() => {
    const getUserData = async () => {
      const response = await window.localStorage.getItem(
        USER_LOCAL_STORAGE_KEY,
      );
      const tempUserData = response ? JSON.parse(response) : null;
      setUserData(tempUserData);
    };
    getUserData();
  }, []);

  return (
    <AuthContext.Provider value={{ userData, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
