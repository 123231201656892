import { Alert } from '@components/Alert/Alert';
import React, { useState } from 'react';
import { ChildrenProps } from './types';

export enum AlertStatus {
  none = 'none',
  success = 'success',
  error = 'danger',
}

export interface AlertInterface {
  alert?: AlertStatus;
  alertText?: string;
  success: (text: string, timeout?: number) => void;
  error: (text: string, timeout?: number) => void;
  clear: () => void;
}
const AlertContext = React.createContext<AlertInterface>(
  {} as unknown as AlertInterface,
);

export const AlertProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [alert, setAlert] = useState(AlertStatus.none);
  const [alertText, setAlertText] = useState<string>('');

  return (
    <AlertContext.Provider
      value={{
        alert,
        alertText,
        success: (text: string, timeout?: number) => {
          setAlertText(text);
          setAlert(AlertStatus.success);
          setTimeout(() => {
            setAlert(AlertStatus.none);
          }, timeout || 10000);
        },
        error: (text: string, timeout?: number) => {
          setAlertText(text);
          setAlert(AlertStatus.error);
          setTimeout(() => {
            setAlert(AlertStatus.none);
          }, timeout || 10000);
        },
        clear: () => setAlert(AlertStatus.none),
      }}
    >
      {children}
      <Alert />
    </AlertContext.Provider>
  );
};
export default AlertContext;
